<template>
  <!-- Start Overlay -->
  <div class="first-time">
    <div @click="getLoading" class="first-time-play-button" v-if="initBtn">
      <play-icon class="first-time-button" />
      <div class="background-ani"></div>
      <!-- <button class="first-time-button"><span class="sr-only">Play story</span></button> -->
    </div>

    <div class="loading-anim">
      <div class="loader05" v-if="!initBtn"></div>
    </div>
    <div class="overlay">
      <img width="100%" height="100%" :src="imageSource(projectId)" alt="" />
    </div>
    <!-- <p class="description">Show project</p> -->
  </div>
</template>

<script>
import { ECOPLOT_API } from '@/constants/urls'
import PlayIcon from '/static/images/embed/play.svg'
export default {
  props: ['projectId', 'isLoadedProject'],
  components: { PlayIcon },

  data() {
    return {
      initBtn: true,
    }
  },

  methods: {
    imageSource(id) {
      return `${ECOPLOT_API}/static/${id}.png`
    },
    getLoading() {
      this.initBtn = false
      this.$emit('loadProject')
    },
  },
  watch: {
    isLoadedProject() {
      if (this.isLoadedProject) {
        this.initBtn = true
      }
    },
  },
}
</script>

<style>
/* .first-time-play-button:hover svg path {
  fill: rgb(255, 65, 108);
} */
</style>
<style scoped>
.first-time-play-button:hover svg {
  width: 50px !important;
  height: 50px !important;
}
.first-time-play-button:hover .background-ani{
  animation: pulse-border 1500ms ease-out infinite;
}
.first-time-button {
  display: block;
  width: 49px;
  height: 49px;
  margin-left: 7px;
  transition: width .3s ease, height .3s ease;
}
.loader05 {
  width: 100px;
  height: 100px;
  border: 4px solid #0052ec;
  border-radius: 50%;
  position: relative;
  animation: loader-scale 1s ease-out infinite;
  top: 50%;
  margin-left: -13px;
}

@keyframes loader-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
/* .first-time {visibility: hidden;} */
.first-time .description {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 22px;
  font-weight: bold;
  color: #f3f3f3;
  z-index: 10005;
  margin-top: 85px;
  transform: translateX(-50%);
}
.loading-anim {
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  transform: translateY(-50%);
}
.first-time-play-button {
  opacity: 1;
  margin-left: -50px;
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #397bff;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-ani {
  /* content: ''; */
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 75px;
  height: 75px;
  background: linear-gradient(118deg, #397bff, rgba(57, 123, 255, 0.7));
  border-radius: 50%;
}
.first-time .overlay {
  pointer-events: none;
  /* background-color: grey; */
  opacity: 0.5;
  z-index: 9000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.4);
    opacity: 0;
  }
}
</style>
