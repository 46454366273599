<template>
  <div class="embed-container">
    <b-card class="mb-0 map-card" v-if="isLoadedProject">
      <div class="map-container">
        <div class="map-list">
          <template v-for="n in numberOfMap">
            <div :key="`divider${n}`" style="width: 4px; background-color: transparent" v-if="n > 1"></div>
            <map-item :key="`map${n}`" :map-index="n - 1" ref="mapItem" />
          </template>
        </div>
      </div>
    </b-card>

    <Timeplayer v-if="isLoadedProject" ref="timeplayer" v-model="date" :isEmbedScreen="true" :speedValue="speed" @speedInput="speed = $event" :modeList="['single']" />
    <start-screen v-if="!isLoadedProject" :projectId="$route.query.projectId" @loadProject="loadProject" :isLoadedProject="isLoadedProject" />
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import MapItem from './MapItem.vue'
import StartScreen from './StartScreen.vue'
import Timeplayer from '../common/Timeplayer.vue'
import DatesMixin from '@/mixins/DatesMixin'
import CompressMixin from '@/mixins/CompressMixin.js'
import { ECOPLOT_API } from '@/constants/urls'
import moment from 'moment'
const axios = require('axios')

export default {
  name: 'embed_component',
  components: { BCard, MapItem, Timeplayer, StartScreen },
  mixins: [DatesMixin, CompressMixin],
  data() {
    return {
      isLoadedProject: false,
    }
  },
  computed: {
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    date: {
      get() {
        return this.$store.state.map.date
      },
      set(date) {
        this.$store.commit('map/UPDATE_DATE', date)
      },
    },
    speed: {
      get() {
        return this.$store.state.map.speed
      },
      set(speed) {
        this.$store.commit('map/UPDATE_SPEED', speed)
      },
    },
  },
  methods: {
    loadProject() {
      try {
        let projectId = this.$route.query.projectId
        if (!projectId) return
        let dateStartAt
        try {
          if (this.$route.query.date) {
            dateStartAt = moment.unix(this.$route.query.date).format('YYYY-MM-DD HH:mm:ss')
          }
        } catch {}
        this.fetchData(projectId, dateStartAt)
      } catch (error) {
        console.log(error)
      }
    },
    // speedChange(speed) {
    //   this.$store.commit('map/UPDATE_SPEED', speed)
    // },
    async fetchData(id, dateStartAt = null) {
      let response = await axios.get(`${ECOPLOT_API}/file/${id}/download/`, { responseType: 'blob' })
      this.extractProject(new File([response.data], 'filename'), null, null, true, true).then(() => {
        this.isLoadedProject = true
        if (dateStartAt) {
          this.$store.commit('map/UPDATE_DATE', [dateStartAt])
        }
      })
    },
  },
}
</script>

<style scoped>
.embed-container .slider-container {
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.map-card {
  overflow: hidden;
  border-radius: 0;
}

.map-container {
  display: flex;
  position: relative;
  overflow: hidden;
}

.map-container .map-list {
  display: flex;
  flex-grow: 1;
}

.map-card .card-body {
  padding: 0;
}
</style>

<style>
.embed-container .slider-container .fps-slider .speed-slider {
  height: 100%;
  width: 100%;
  margin-left: 0 !important;
  background-color: rgba(57, 123, 255, 0.12);
  position: absolute;
  top: 0;
  left: 0;
}
.embed-container .slider-container .fps-slider .speed-slider .noUi-handle {
  background-color: #397bff;
}
.embed-container .slider-container .fps-slider .speed-slider .noUi-handle::before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 30px;
  height: 30px;
  background: rgba(57, 123, 255, 0.12);
  border-radius: 50%;
}
.embed-container .slider-container .mode-dropdown .dropdown-menu {
  background-color: transparent;
  padding: 0px;
  min-width: unset;
  height: 130px;
  width: 60%;
  top: -13px !important;
  padding: 5px 0px 10px 0px;
}
.embed-container .slider-container .mode-dropdown .dropdown-menu li {
  position: relative;
  width: 3px;
  height: 100%;
}
.embed-container .slider-container .mode-dropdown .dropdown-menu li .dropdown-item {
  padding: 0;
  height: 100%;
}
.embed-container .slider-container .noUi-vertical .noUi-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: -4px;
  bottom: -10px;
}
.embed-container .slider-container .noUi-pips-vertical {
  padding: 0px 2px;
  top: 3px;
}
.embed-container .slider-container .noUi-marker-vertical.noUi-marker {
  height: 1px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 12px;
}
.embed-container .noUi-value-vertical {
  padding-left: 15px;
  font-size: 10px;
}
.embed-container .dropdown-toggle-no-caret {
  display: flex;
}
.embed-container .dropdown-toggle-no-caret span {
  align-self: center;
  font-size: 11px;
  padding-left: 5px;
}
.noUi-base {
  height: 101%;
}
</style>
